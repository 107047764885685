<template>
    <w-flex column class="grow mx3" v-show="true">
        <w-tabs :items="tabs" :transition="transition">
            <template #item-content.1>

                <!-- Tabelle Arbeitsheft -->
                <w-table :headers="table_workbook.headers" :items="table_workbook.items" class="body text-light" no-headers>
                    <template #no-data>
                        {{ no_data }}
                    </template>
                    <template #item="{ item, index, select, classes }">
                        <tr :class="{
                            ...classes,
                            'blue-grey-light5--bg': index % 2,
                            '': !(index % 2)
                        }" @click="select">
                            <td :colspan="table_workbook.headers.length">
                                <div class="title3">
                                    {{ get_date(item.mwb_week_date) }}
                                </div>

                                <table style="width: 100%">
                                    <tr v-for="(header, i) in table_workbook.headers" :key="i" class="py8">
                                        <td style="width: 280px;" class="text-bold pl2">
                                            {{ header.label }}
                                        </td>
                                        <td class="td-hover" @click="copy">
                                            {{ item[header.key] || '' }}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </template>
                </w-table>

            </template>

            <template #item-content.2>

                <!-- Tabelle watchtower -->
                <w-table :headers="table_watchtower.headers" :items="table_watchtower.items" class="body text-light"
                    no-headers>
                    <template #no-data>
                        {{ no_data }}
                    </template>
                    <template #item="{ item, index, select, classes }">
                        <tr :class="{
                            ...classes,
                            'blue-grey-light5--bg': index % 2,
                            '': !(index % 2)
                        }" @click="select">
                            <td :colspan="table_watchtower.headers.length">
                                <div class="title3">
                                    {{ get_date(item.w_study_date) }}
                                </div>

                                <table style="width: 100%">
                                    <tr v-for="(header, i) in table_watchtower.headers" :key="i" class="py8">
                                        <td style="width: 280px;" class="text-bold pl2">
                                            {{ header.label }}
                                        </td>
                                        <td class="td-hover" @click="copy">
                                            {{ item[header.key] || '' }}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </template>
                </w-table>

            </template>
        </w-tabs>
    </w-flex>
</template>


<script>
import * as store from '@/store/store.js'
import copy from 'copy-text-to-clipboard';


export default {
    data() {
        return {
            no_data: "🤷‍♂️ Keine Daten vorhanden ❗",
            tabs: [
                { title: 'Arbeitsheft', content: 'Tab 1 content.' },
                { title: 'Wachtturm', content: 'Tab 2 content.' },
            ],
            transition: 'fade',

            table_workbook: {
                // Daten für die Tabelle: Arbeitsheft
                headers: store.getter.get_workbook_lable(),
                items: store.getter.get_workbook(),
            },
            table_watchtower: {
                // Daten für die Tabelle: Wachtturm
                headers: store.getter.get_watchtower_lable(),
                items: store.getter.get_watchtower(),
            }

        }
    },
    methods: {
        get_date(date_string) {
            // Datum optisch ansprechender darstellen
            let date = new Date(date_string);
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString('de', options);

        },
        copy(item) {
            //Kopier Funktion
            copy(item.target.textContent)
        }
    }

}
</script>

<style scoped>
tr {
    font-size: 13px;
}

.td-hover {
    padding: 4px 16px 4px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.td-hover:not(:empty):hover {
    color: var(--w-primary-color);
    cursor: pointer;
}

.td-hover:not(:empty):hover:after {
    position: absolute;
    right: 5px;
    background-image: url('../assets/clipboard.png');
    background-size: 12px 12px;
    background-position: center center;
    display: inline-block;
    width: 13px;
    height: 13px;
    content: "";
    z-index: 99;
}
</style>