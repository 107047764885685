<template>
    <w-flex justify-end class="ma3">
        <w-button class="ma1" @click="home_button_click('Home')">Zurück</w-button>
    </w-flex>

    <w-flex class="ma3 column">

        <p class="lh2">
            Herzlichen Glückwunsch! Dank deiner Neugier hast du diese versteckte Seite gefunden.
            Hier findest du einen kurzen Überblick über die Entwicklung der Webanwendung.
        </p>
        <p class="text-upper ma5 text-bold">
            📄 Version:
        </p>
        <w-timeline class="mx7 body" :items="items" color="primary"></w-timeline>

    </w-flex>
</template>

<script>
export default {
    name: "Version",
    data() {
        return {
            items: [
                { title: 'Version 0.0.8', content: 'Update jw-epub-parser to v3.23.5', icon: 'wi-info-circle' },
                { title: 'Version 0.0.7', content: 'Update jw-epub-parser to v3.18.0', icon: 'wi-info-circle' },
                { title: 'Version 0.0.6', content: 'Update jw-epub-parser to v3.14.0', icon: 'wi-info-circle' },
                { title: 'Version 0.0.5', content: 'Update jw-epub-parser to v3.12.1', icon: 'wi-info-circle' },
                { title: 'Version 0.0.4', content: 'Update jw-epub-parser to v3.11.0', icon: 'wi-info-circle' },
                { title: 'Version 0.0.3', content: 'Erstellung des Versionsverlaufs', icon: 'wi-check-circle' },
                { title: 'Version 0.0.2', content: 'Update jw-epub-parser to v3.9.0', icon: 'wi-info-circle' },
                { title: 'Version 0.0.1', content: 'Die erste Version des JW ePUB-Parser ist online. Viel Spaß damit! 😉', icon: 'wi-check-circle' }
            ]
        }
    },
    methods: {
        home_button_click(component) {
            this.$emit('home_button_click', component);
        }
    }
}
</script>

<style scoped></style>
