<template>
    <w-flex justify-end class="ma3">
        <w-button class="ma1" @click="home_button_click('Home')">Zurück</w-button>
    </w-flex>

    <w-flex class="ma3 column">

        <p class="lh2">
            JW ePUB-Parser ist eine Webanwendung, die es ermöglicht,
            Daten aus einer oder mehreren jw-ePUB-Dateien zu extrahieren.
            Die Anwendung unterstützt das Arbeitsheft und den Wachtturm von JW.ORG.
        </p>
        <p class="text-upper ma5 text-bold">
            📄 Anleitung:
        </p>
        <w-timeline class="mx7 body" :items="items"></w-timeline>

        <p class="text-upper ma5 text-bold">
            📄 Geplante Funktionen:
        </p>
        <ul class="mx8">
            <li>Daten exportieren</li>
            <li>Verbesserung der Benutzerfreundlichkeit</li>
        </ul>

        <p class="text-upper ma5 text-bold">
            🎁 Danksagung:
        </p>
        <p class="body mx8 mb5">
            Vielen Dank an @rhahao! <br>
            Seine Entwicklung und seine Arbeit an der Bibliothek <a href="https://github.com/sws2apps/jw-epub-parser"
                target="_blank">jw-epub-parser</a> haben dieses Projekt
            erst möglich gemacht.

        </p>

        <p class="text-italic body mt5">
            Die Webanwendung wurde mit viel Sorgfalt entwickelt.
            Derzeit befindet sich die Plattform noch in einer frühen Beta-Phase.
            Eventuelle Fehler und Probleme sind nicht ausgeschlossen.
            Sollte euch ein Fehler auffallen, könnt ihr gern mit mir Kontakt aufnehmen.
        </p>

    </w-flex>
</template>

<script>
export default {
    name: "Info",
    data() {
        return {
            items: [
                { title: 'Download', content: 'Lade dir ein oder mehrere Arbeitshefte/ Wachttürme von www.jw.org herunter.' },
                { title: 'JW ePUB-Parser starten', content: 'Öffnen Sie die JW ePUB-Parser Seite und klicken Sie auf die Schaltfläche "Start".' },
                { title: 'Import', content: 'Es ist möglich, mehrere ePUB-Dateien gleichzeitig zu öffnen.' },
                { title: 'Tabelle laden', content: 'Alle Daten aus den Arbeitsheften/Wachttürmen werden übersichtlich in Tabellenform dargestellt. Durch einen Klick auf die Einträge kannst du sie automatisch kopieren.' }
            ]
        }
    },
    methods: {
        home_button_click(component) {
            this.$emit('home_button_click', component);
        }
    }
}
</script>

<style scoped></style>