<template>
    <w-flex justify-center class="mxa pa3">
        <w-tag class="mr4" bg-color="primary">Made with 💙</w-tag>
        <w-tag class="mr4" bg-color="primary" @click="home_button_click('Version')">Version: {{ version }}</w-tag>
        <w-tag class="mr4" bg-color="primary">🌐 hosted by kiesl.org</w-tag>
    </w-flex>
</template>

<script>
export default {
    name: "Footer",
    props: {
        version: String
    },
    methods: {
        //Click Event an Parent Component übergeben -> Component wird gewechselt.
        home_button_click(component) {
            this.$emit('home_button_click', component);
        }
    }

}
</script>

<style scoped></style>