<template>
    <w-toolbar shadow>
        <w-flex justify-center align-center>
            <w-flex>
                <w-image :src="require('../assets/logo.png')" :width="30" :height="30"></w-image>
                <div class="title1 ml5">{{ title }}</div>
            </w-flex>
            <w-image :src="require('../assets/info.png')" :width="20" :height="20" @click="home_button_click('Info')" id="info"></w-image>
        </w-flex>
    </w-toolbar>
</template>


<script>


export default {
    name: "Header",
    props: {
        title: String
    },
    methods: {
        //Click Event an Parent Component übergeben -> Component wird gewechselt.
        home_button_click(component) {
            this.$emit('home_button_click', component);
        }
    }
}
</script>

<style scoped>

#info {
    cursor: pointer;
}

</style>