import { createApp } from 'vue'
import App from './App.vue'

import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'

import VueMatomo from 'vue-matomo'

createApp(App)
    .use(WaveUI, { /* Some Wave UI options */ })
    .use(VueMatomo, {
        // Configure your matomo server and site by providing
        host: 'https://fern.my-router.de/matomo/',
        siteId: 7,
    })
    .mount('#app')

window._paq.push(['trackPageView']); //To track pageview
