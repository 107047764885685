import { reactive } from 'vue';

const store = reactive({
    files: [],
    workbook: [],
    watchtower: [],
    meeting_workbook_lable: new Map([
        ["mwb_week_date", "Datum"],
        ["mwb_week_date_locale", "Woche vom"],
        ["mwb_weekly_bible_reading", "Bibelkapitel"],
        ["mwb_song_first", "Lied 1"],
        ["mwb_tgw_talk", "Vortrag"],
        ["mwb_tgw_bread", "Bibellesung"],
        ["mwb_ayf_count", "Anzahl Schulungsaufgaben"],
        ["mwb_ayf_part1", "Aufgabe 1"],
        ["mwb_ayf_part1_time", "Aufgabe 1 (min)"],
        ["mwb_ayf_part1_type", "Aufgabe 1 Typ"],
        ["mwb_ayf_part2", "Aufgabe 2"],
        ["mwb_ayf_part2_time", "Aufgabe 2 (min)"],
        ["mwb_ayf_part2_type", "Aufgabe 2 Typ"],
        ["mwb_ayf_part3", "Aufgabe 3"],
        ["mwb_ayf_part3_time", "Aufgabe 3 (min)"],
        ["mwb_ayf_part3_type", "Aufgabe 3 Typ"],
        ["mwb_ayf_part4", "Aufgabe 4"],
        ["mwb_ayf_part4_time", "Aufgabe 4 (min)"],
        ["mwb_ayf_part4_type", "Aufgabe 4 Typ"],
        ["mwb_song_middle", "Lied 2"],
        ["mwb_lc_count", "Anzahl Aufgaben"],
        ["mwb_lc_part1", "Leben als Christ 1"],
        ["mwb_lc_part1_time", "Leben als Christ 1 (min)"],
        ["mwb_lc_part1_content", "Leben als Christ 1 Beschreibung"],
        ["mwb_lc_part2", "Leben als Christ"],
        ["mwb_lc_part2_time", "Leben als Christ 2 (min)"],
        ["mwb_lc_part2_content", "Leben als Christ 2 Beschreibung"],
        ["mwb_lc_cbs", "Versammlungs­bibelstudium"],
        ["mwb_song_conclude", "Lied 3"],
    ]

    ),
    watchtower_study_lable: new Map([
        ["w_study_date", "Datum"],
        ["w_study_date_locale", "Woche vom"],
        ["w_study_title", "Studienartikel"],
        ["w_study_opening_song", "Anfangslied"],
        ["w_study_concluding_song", "Schlusslied"],
    ])
})


const action = {}

const mutation = {
    set_files(file) {
        store.files.push(file);
    },

    set_workbook(arr) {
        store.workbook.push(arr);
    },

    set_watchtower(arr) {
        store.watchtower.push(arr);
    },

    delete_all() {
        store.files = [];
        store.workbook = [];
        store.watchtower = [];
    }
}

const getter = {
    get_files() {
        return store.files

    },
    get_files_lenght() {
        return store.files.length
    },
    get_workbook() {
        let arr = []

        store.workbook.forEach(e => {
            e.forEach(e => {
                arr.push(e)
            })

        })
        return arr //store.workbook
    },

    get_watchtower() {
        let arr = []

        store.watchtower.forEach(e => {
            e.forEach(e => {
                arr.push(e)
            })

        })
        return arr //store.watchtower
    },
    get_watchtower_lable() {
        let arr = [];
        let id = 1;

        store.watchtower_study_lable.forEach((value, key) => {
            arr.push(
                { 'id': id, 'label': value, 'key': key, align: 'left' }
            )
            id++
        })
        return arr
    },
    get_workbook_lable() {

        let arr = [];
        let id = 1;

        store.meeting_workbook_lable.forEach((value, key) => {
            arr.push(
                { 'id': id, 'label': value, 'key': key, align: 'left' }
            )
            id++
        })
        return arr

    }
}



export {
    store,
    action,
    mutation,
    getter
}