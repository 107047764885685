<template>
    <w-card class="grow mx3 mb8">
        <w-form>
            <div class="grow mx1">
                <w-flex column justify-start class="wrapper mx8">
                    <w-input type="file" class="mb3" label-position="left" accept=".epub" outline multiple label="Datein:"
                        :preview="false" @input="(e) => handle_open_epub(e)">
                    </w-input>
                </w-flex>

                <w-transition-fade>
                    <w-flex v-show="show_loaded" class="mx8 my3 transition" wrap align-center>
                        <p class="body primary fill-width mb2">Folgende Datein wurden gefunden:</p>
                        <w-flex wrap>
                            <!-- Dateiname der geladenen ePUB Datein w8ird als Tag dargestellt -->
                            <w-tag v-for="file in store_file()" :key="file.name" class="mx1 mb1" bg-color="primary">{{
                                file.name
                            }}</w-tag>
                        </w-flex>
                    </w-flex>
                </w-transition-fade>
            </div>
        </w-form>
    </w-card>

    <w-dialog v-model="show_error" title="Fehler" color="error-light1">
        <!-- Dialogfeld für Fehlermeldungen -->
        {{ error_msg }}
    </w-dialog>
</template>



<script>
//import { store, action } from '@/store/store.js'
import { loadEPUB } from 'jw-epub-parser';
import * as store from '../store/store.js'

export default {
    name: "Import",
    data() {
        return {
            show_loaded: false, // Tags werden nur angezeigt, wenn Daten zur Verfügung stehen
            show_error: false, // Error wird nur Angeziegt wenn Fehler vorliegt: Bool
            error_msg: "", // Error Nachricht: String

        }
    },
    methods: {
        async handle_open_epub(files) {
            // Bei jeden neuen laden alle Fehler zurücksetzen 
            this.show_loaded = false;
            this.show_error = false;
            this.error_msg = "";
            // Bei jeden laden alle Daten im Store zurücksetzen
            store.mutation.delete_all();

            let i = 0;
            let files_lenght = files.length;

            for (i; i < files_lenght; i++) {
                if (this.input_file_check(files[i].name)) {
                    //Parameter der Datein in store.files speichern
                    store.mutation.set_files(files[i].file);

                    switch (true) {
                        case (files[i].name.startsWith("w_") == true):
                            try {
                                // Daten aus dem Wachtturm extrahieren
                                let data = await loadEPUB(files[i].file);
                                store.mutation.set_watchtower(data);
                            } catch (err) {
                                console.error(err);
                            }
                            break
                        case (files[i].name.startsWith("mwb_X_") == true):
                            try {
                                // Daten aus dem Arbeitsheft extrahieren
                                let data = await loadEPUB(files[i].file);
                                store.mutation.set_workbook(data);
                            } catch (err) {
                                console.error(err);
                            }
                            break
                        default:
                            break
                    }

                } else {
                    continue;
                }
            }

            if (store.getter.get_files_lenght() > 0) {
                // Tags anzeigen: True
                this.show_loaded = true;
                // Event an Parent senden
                this.$emit('epub_load_completed', true);

            } else {
                // Alternativ Error auswerfen
                this.show_error = true;
                this.error_msg = "Die ePUB Datein konnten nicht gelesen werden versuchen Sie es später noch einmal.";
            }
        },

        input_file_check(file_name) {
            //Regex Pattern für Dateiname
            let pattern = new RegExp(/^(mwb_X_|w_X_)(\d{6}).epub$/);
            //Prüfung *nicht* erfolgreich
            if (!pattern.test(file_name)) {
                return false
            } else {
                //Prüfung erfolgreich
                return true
            }
        },
        store_file() {
            // Tag -> Name der Datein
            return store.getter.get_files();
        }
    },
}
</script>

<style></style>