<template>
    <w-accordion :items=accordion_items :expand-icon="false" v-model="accordion">

        <template #item-title.1>ePUB Datei öffnen</template>
        <template #item-content.1>
            <!-- Input Feld um die ePUB Datein zu öffen -->
            <Import @epub_load_completed="epub_load_completed()"></Import>
        </template>

        <template #item-title.2>Daten als Tabelle</template>
        <template #item-content.2>
            <!-- Beide Tabelle Arbeitsheft und Wachtturm darstellen -->
            <Table></Table>
        </template>
    </w-accordion>
</template>

<script>
import Import from '@/components/Import.vue'
import Table from '@/components/Table.vue'

import * as store from '../store/store.js'

export default {

    name: 'Action',
    components: {
        Import,
        Table,
    },
    data() {
        return {
            accordion: [true, true], //true: Import true: Tabelle
            accordion_items: 1, // derzeit wird nur der Import angezeigt
        }
    },
    methods: {
        epub_load_completed() {
            // Wenn Daten geladen sind, dann wird erst die Tabelle angeziegt
            if (store.getter.get_files_lenght() > 0) this.accordion_items = 2
        }
    },
}
</script>

<style scoped></style>