<template>
    <div id="img">
        <!-- <img :src="require('../assets/berge.jpg')" alt="Berge"> -->
        <span id="overlayer" class="pa3" style="max-width: 550px">
            <p class="title2 mt5 mb1">
                Herzlich Willkommen.<br>
            </p>
            <p class="title3 mb5">
                Diese Webanwendung ermöglicht die Datenextraktion aus einer oder mehreren jw-ePUB-Dateien.
            </p>
            <div class="ma5">
                <!-- Click auf Button übergibt Name der Componten der Aufgerufen werden soll -->
                <w-button class="ma1" bg-color="primary" lg @click="home_button_click('Action')">🚀 Start</w-button>
                <w-button class="ma1" bg-color="primary" lg @click="home_button_click('Info')">Anleitung</w-button>
            </div>

        </span>
    </div>
</template>

<script>
export default {
    name: "Home",
    methods: {
        home_button_click(component) {
            // Name der Componten an Parent übergeben, damit diese gewechselt werden kann
            this.$emit('home_button_click', component);
        }
    }


}
</script>

<style scoped>
#img {
    margin-top: -10px;
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url('../assets/berge.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

img {
    object-fit: cover;
    min-width: 100%;
    max-height: 100%;
}

#overlayer {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 15px;
}
</style>