<template>
  <header>
    <Header @home_button_click="changeComponent" title="JW ePUB-Parser"></Header>
  </header>
  <main class="grow mt3">
    <!-- Componten dynamisch laden -> einfacher Router -->
    <component v-bind:is="currentComponent" @home_button_click="changeComponent"></component>

  </main>
  <footer>
    <Footer :version=version_package_json @home_button_click="changeComponent"></Footer>
  </footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

import { version } from "../package.json"

// View
import Home from '@/View/Home.vue'
import Action from '@/View/Action.vue'
import Info from '@/View/Info.vue'
import Version from '@/View/Version.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Home,
    Action,
    Info,
    Version
  },
  data() {
    return {
      currentComponent: Home,
      version_package_json: version,
    }
  },
  methods: {
    changeComponent(componentName) {
      this.currentComponent = componentName;
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&family=Poppins:wght@300&family=Roboto+Mono:wght@500&family=Sintony&display=swap');

#app {
  font-family: 'Nunito', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto Mono', monospace;
  font-family: 'Sintony', sans-serif;
}
</style>

